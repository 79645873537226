/*
HOW TO:
    import wAxios from '@/plugins/w/axios'
    wAxios.get
    wAxios.post
    wAxios.get_auth
    wAxios.post_auth
*/

import axios from 'axios'
import store from '@/store'

axios.defaults.baseURL = process.env.VUE_APP_WAXIOS_URL

export default {

    async get_data(url, config?) {
        return axios.get(url, config)
            .then(res => res.data.data)
            .catch(err => Promise.reject(err.response))
    },

    async get(url, config?) {
        return axios.get(url, config)
            .then(res => res.data)
            .catch(err => Promise.reject(err))
    },

    async post_data(url, params = null, config?) {

        return axios.post(url, params, config)
            .then(res => res.data.data)
            .catch(err => Promise.reject(err.response))
    },

    async post(url, params = null, config?) {

        return axios.post(url, params, config)
            .then(res => res.data)
            .catch(err => Promise.reject(err))
    },

    async get_auth_data(url, config?) {
        config = this._config(config)

        return axios.get(url, config)
            .then(res => res.data.data)
            .catch(err => Promise.reject(err.response))
    },

    async get_auth(url, config?) {
        config = this._config(config)

        return axios.get(url, config)
            .then(res => res.data)
            .catch(err => Promise.reject(err))
    },

    async post_auth_data(url, params=null, config?) {
        config = this._config(config)

        return axios.post(url, params, config)
            .then(res => res.data.data)
            .catch(err => Promise.reject(err.response))
    },

    async post_auth(url, params=null, config?) {
        config = this._config(config)

        return axios.post(url, params, config)
            .then(res => res.data)
            .catch(err => Promise.reject(err))
    },

    async delete_auth_data(url, params?, config?) {
        config = this._config(config)
        if(params)
            config.data = params

        return axios.delete(url, config)
            .then(res => res.data.data)
            .catch(err => Promise.reject(err.response))
    },

    async patch_auth_data(url, params=null, config?) {
        config = this._config(config)

        return axios.patch(url, params, config)
            .then(res => res.data.data)
            .catch(err => Promise.reject(err.response))
    },

    async patch_auth(url, params=null, config?) {
        config = this._config(config)

        return axios.patch(url, params, config)
            .then(res => res.data)
            .catch(err => Promise.reject(err))
    },


    //////////////////////////////////////////////////////////////////////////////
    // helpers

    _config(config?) {
        // if (config) console.error('NOT IMPLEMENTED')
        const headers = {
            Authorization: `Bearer ${store.getters['wAuth/token']}`
        }

        const venueId = store.getters['venues/activeVenueId']
        if(venueId)
            headers['X-Venue-Id'] = `${store.getters['venues/activeVenueId']}`

        return {headers}
    }
}
