import wAxios from '@/plugins/w/axios'

const initState = {
  policies: [],
  policiesSearch: [],
  policiesUpdateQueue: []
}

export default {
  namespaced: true,
  state: initState,
  mutations: {
    updateSearch(state, data) {
      state.policiesSearch = data

      // TODO update search witch update queue

    },
    updatePolicies(state, data) {
      state.policies = data
    },
    clearSearchPolicies(state) {
      state.policiesSearch = []
    },
    clearPoliciesUpdateQueue(state) {
      state.policiesUpdateQueue = []
    },
    updatePolicyPricing(state, data) {
      const isQueued = state.policiesUpdateQueue.filter(v => v.oid == data.oid).length
      if(!isQueued)
        state.policiesUpdateQueue.push({oid: data.oid, price_custom: data.newPrice})
      else
        state.policiesUpdateQueue = state.policiesUpdateQueue.map( v =>
          v.oid == data.oid
            ? {...v, price_custom: data.newPrice}
            : v
        )
    },
    emptyStore(state) {
      state = initState
    }
  },
  actions: {
    async loadUserPolicies({commit}) {
      const res = await wAxios.get_auth_data(`v1/price_policy`)
      commit('updatePolicies', res)
    },
    async searchPolicy({commit}, s) {
      const res = await wAxios.get_auth_data(`v1/price_policy/search?q=${s}`)
      commit('updateSearch', res)
    },
    async submitPolicyUpdate({commit, state}) {
      return new Promise((resolve, reject) => {
        wAxios.post_auth_data(`v1/price_policy`, state.policiesUpdateQueue)
          .then((res) => {
            commit('clearSearchPolicies')
            commit('clearPoliciesUpdateQueue')
            commit('updatePolicies', res)
            resolve(res)
          }).catch(error => {
          reject(error)
        })
      })

    }
  },
  getters: {
    policiesSearch: (state) =>  {
      return state.policiesSearch
    },
    policies: (state) =>  state.policies,
    policiesUpdateQueue: (state) =>  state.policiesUpdateQueue,
  }
}