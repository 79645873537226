<template>
  <div v-if="activeVenue">
    <b-card class="card" header="Upraviť prevádzku">

      <template v-for="(field, i) in fields">
        <b-form-group :label="field.title" :label-for="field.name" :key="i">
          <b-form-input :ref="'focusInput'" :id="field.name" v-model="$v.activeVenue[field.name].$model" :state="validateState(field.name)" type="text"/>
          <b-form-invalid-feedback>{{field.warning}}</b-form-invalid-feedback>
        </b-form-group>
      </template>


      <b-button class="float-right" @click="onDelete" variant="primary" v-if="venueId && !demoDomain">Vymazať</b-button>
      &nbsp;
      <b-button @click="onSubmit">Uložiť</b-button>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue'
import {mapActions, mapGetters} from "vuex"
import { required, minLength } from "vuelidate/lib/validators"

export default Vue.extend({
  name: 'venue-modal',
  props: ['venueId'],
  data: function () {
    return {
      activeVenue: {
        name: null,
        street: null,
        city: null,
        zip: null,
        country: null,
      },
      fields: [
        { name: 'name', title: 'Názov prevádzky', warning: 'Toto pole je povinné a musí obsahovať aspoň 3 znaky.'},
      ]
    }
  },
  computed: {
    ...mapGetters('venues', ['venues']),
    demoDomain() {
      return process.env.VUE_APP_DEMO_DOMAIN === document.location.host
    },
  },
  mounted() {
    if(this.venueId)
      this.activeVenue = {... this.venues.find( v => v.id === this.venueId)}

    setTimeout(_ => this.$refs.focusInput[0].focus(), 300)
  },
  validations: {
    activeVenue: {
      name: {required, minLength: minLength(3)},
    }
  },
  methods: {
    ...mapActions('venues', ['updateVenueInfo', 'deleteVenue']),

    validateState(field) {

      const { $dirty, $error } = this.$v.activeVenue[field]
      return $dirty ? !$error : null
    },

    onSubmit: function () {
      this.$v.activeVenue.$touch()
      if (this.$v.activeVenue.$anyError)
        return

      this.$v.activeVenue.$reset()
      this.$wToast.clear_loading()

      this.updateVenueInfo({venue: this.activeVenue, id: this.venueId}).then( _ => {
        this.$wToast.success('Zmeny boli uložené!')
        this.$emit('close')
      }).catch(e => this.$wToast.error(e))

    },
    onDelete: async function () {
      await this.deleteVenue(this.venueId)
      this.$emit('close')
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
