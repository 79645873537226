import axios from 'axios'
import store from '@/store'

let isRefreshing = false
let refreshHook = null

async function _unauthorised(error) {
    if(error.response.data.error === "token_invalid") {
        store.commit('wAuth/logout')
        setTimeout(_ => window.location.reload(), 500)
    }

    if(!error.response || error.response.status != 401) {
        return Promise.reject(error)
    }

    if(!isRefreshing) {
        refreshHook = new Promise((resolve, reject) => {
            store.dispatch('wAuth/refreshToken')
                .then((token) => resolve(token))
                .catch((error) => reject(error))
                .finally(() => isRefreshing = false)
        })

        isRefreshing = true
    }

    try {
        const token = await refreshHook
        error.config.headers = {
            'Authorization': `Bearer ${token}`
        }
        const venueId = store.getters['venues/activeVenueId']
        if(venueId)
            error.config.headers['X-Venue-Id'] = `${store.getters['venues/activeVenueId']}`

        return Promise.resolve(await axios.request(error.config))
    } catch(error) {
        console.log(error)
    }
}

axios.interceptors.response.use(
    (response) => response,
    (err) => _unauthorised(err)
)