<template>
  <b-button v-if="!venues.length" @click="onOpenModal('')">Vytvoriť novú prevádzku</b-button>
  <b-nav-item-dropdown id="venue" v-else>
    <template #button-content>
      {{ activeVenueName }}
    </template>

    <b-dropdown-item link-class="venue-item" v-for="(venue, i) in venues" :key="i" @click="onSwitchVenue(i)">
      {{ venue.name }}
      <b-button class="venue-item-button float-right" size="sm" @click.prevent="onOpenModal(venue.id)">&hellip;</b-button>
    </b-dropdown-item>

    <b-dropdown-divider v-if="!demoDomain" ></b-dropdown-divider>

    <b-dropdown-item v-if="!demoDomain" link-class="new-venue-bottom" @click="onOpenModal('')">
      Vytvoriť novú prevádzku
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>
<script>
import Vue from 'vue'
import {mapActions, mapGetters} from "vuex"
import VenueModal from './venue-modal'
import load from '@/plugins/app/load'

export default Vue.extend({
  name: 'venue-switcher',
  computed: {
    ...mapGetters('venues', ['venues', 'activeVenueId', 'isLoaded']),
    activeVenueName: function () {
      const venue = this.venues.find(v => v.id === this.activeVenueId)
      if(venue) {
        return venue.name
      } else {
        this.switchVenueId(this.venues[0].id)
        return this.venues[0].name
      }
    },
    demoDomain() {
      return process.env.VUE_APP_DEMO_DOMAIN === document.location.host
    },
  },
  watch: {
    venues() {
      if(this.isLoaded)
        this._venuesOnboardingGuard()
    }
  },
  mounted() {
    if(this.isLoaded)
      this._venuesOnboardingGuard()
  },
  methods: {
    ...mapActions('venues', ['switchVenueId']),
    onSwitchVenue: function (i) {
      this.switchVenueId(this.venues[i].id)
      load.loadVenueData()
    },
    onOpenModal: function (id) {
      this.$modal.show( VenueModal, {venueId: id} )
    },
    _venuesOnboardingGuard() {
      if(!this.venues.length) {
        this.$router.push('/login/onboarding')
      }
    }
  }
})
</script>
<style lang="scss">
.navbar .new-venue-bottom{
  padding: 0.5rem 50px 0.5rem 0.5rem;
}

</style>
<style lang="scss" scoped>
.venue-item-button {
  position: relative;
  top: -4px;
  right: -7px;
}
</style>
