import wAxios from '@/plugins/w/axios'
import format from "@/plugins/app/formatters"

const initState = {
  busySearch: false,
  ingredientsSearched: [],
  ingredientsSelected: [],
  recipesSearched: [],
  recipesOwned: [],
  recipesLiked: [],
  activeRecipe: {},
  ingredientsMap: {},
  recipesSearchFilters: {
    diet: [],
    cuisine: []
  }
}

export default {
  namespaced: true,
  state: initState,
  mutations: {
    updateSearch(state, data) {
      if(process.env.VUE_APP_DEMO_DOMAIN === document.location.host) {
        data = data.filter( e => e.owned === true)
      }

      state.recipesSearched = data
    },
    updateBusySearch(state, data) {
      state.busySearch = data
    },
    updateIngredientsMap(state, data) {
      data.forEach( item => {
        if(!item.products)
          return

        const products = {}
        item.products.forEach( v => products[v.oid] = v)
        state.ingredientsMap[item.id] = products
      })
    },

    updateSearchedIngredients(state, data) {
      state.ingredientsSearched = data
    },
    updateSelectedIngredients(state, data) {
      state.ingredientsSelected = data
    },
    updateOwned(state, data) {
      state.recipesOwned = data
    },
    updateLiked(state, data) {
      state.recipesLiked = data
    },
    updateActiveRecipe(state, data) {
      state.activeRecipe = data
    },
    clearSearchRecipes(state) {
      state.recipesSearched = []
    },
    clearSearchIngredients(state) {
      state.ingredientsSearched = []
    },
    clearActiveRecipe(state) {
      state.activeRecipe = {}
    },
    clearSearchRecipesFilters(state) {
      state.recipesSearchFilters = {
        diet: [],
        cuisine: []
      }
    },
    updateSearchFilter(state, filters) {
      state.recipesSearchFilters = filters
    },
    toggleRecipeLike(state, recipeId) {
      ['recipesSearched'].forEach( stateId => {
        state[stateId] = state[stateId].map( v =>
          v.id == recipeId
            ? {...v, liked: !v.liked}
            : v
        )
      })

      if(state.activeRecipe.id == recipeId)
        state.activeRecipe.liked = !state.activeRecipe.liked
    },
    emptyStore(state) {
      state = initState
    }
  },
  actions: {
    async loadRecipes({commit}) {
      const res = await wAxios.get_auth_data(`v1/recipe`)
      commit('updateLiked', res.liked)
      commit('updateOwned', res.owned)
    },
    async loadRecipe({commit}, id) {
      const res = await wAxios.get_auth_data(`v1/recipe/${id}`)
      commit('updateActiveRecipe', res)
    },

    async removeRecipe({commit, dispatch}, id) {
      const res = await wAxios.delete_auth_data(`v1/recipe/${id}`)
      dispatch('loadRecipes')
      commit('clearActiveRecipe', res)
    },

    async searchRecipes({commit, state}, s) {
      commit('updateBusySearch', true)
      const params = {
        q: s,
        diet_id: state.recipesSearchFilters.diet.join(","),
        cuisine_id: state.recipesSearchFilters.cuisine.join(","),
      }

      const res = await wAxios.get_auth_data(`v1/recipe/search?${format.params(params)}`)
      commit('updateSearch', res)
      commit('updateBusySearch', false)
    },
    async searchIngredients({commit, state}, s) {
      const params = {
        q: s
      }
      const res = await wAxios.get_auth_data(`v1/ingredient/search?${format.params(params)}`)
      commit('updateSearchedIngredients', res)
      commit('updateIngredientsMap', res)
    },
    async preloadIngredients({commit, state}, items) {
      for (const id of items) {
        const res = await wAxios.get_auth_data(`v1/enum/ingredient/${id}`)
        commit('updateIngredientsMap', [res])
      }
    },
    async toggleRecipeLike({commit, dispatch}, data) {
      await wAxios.post_auth_data(`v1/recipe/like`, data).then( _ => {
        data.forEach( recipe => {
          commit('toggleRecipeLike', recipe.id)
          dispatch('loadRecipes')
        })
      })
    }
  },
  getters: {
    ingredientsMap: (state) => state.ingredientsMap,
    ingredientsSelected: (state) => state.ingredientsSelected,
    ingredientsSearched: (state) => state.ingredientsSearched,
    recipesSearched: (state) => state.recipesSearched,
    recipesLiked: (state) =>  state.recipesLiked,
    recipesOwned: (state) =>  state.recipesOwned,
    activeRecipe: (state) =>  state.activeRecipe,
    busySearch: (state) => state.busySearch,
  }
}
