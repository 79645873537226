import Vue from 'vue'
import Sortable from "sortablejs"

Vue.directive('table-sort', {
  bind(el, binding) {
    const table = el
    table._sortable = Sortable.create(table.querySelector('tbody'), {
      ...binding.value
    })
  }
})