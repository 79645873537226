import wAxios from '@/plugins/w/axios'

const initState = {
  busySearch: false,
  carte: []
}

export default {
  namespaced: true,
  state: initState,
  mutations: {
    updateCarte(state, data) {
      state.carte = data
    },
    updateBusySearch(state, data) {
      state.busySearch = data
    },
    removedCarte(state, data) {
      state.carte = state.carte.filter( v => v.id != data.id)
    },
    editOrAddCarte(state, data) {
      if(data.id)
        state.carte = state.carte.map( v =>
          v.id == data.id
            ? {...v, name: data.name}
            : v
        )
      else
        state.carte.push(data)
    },
    addRecipeToCarte(state, data) {
      state.carte.forEach(carte => {
        if(carte.id == data.id)
          carte.recipes.push({id: data.recipeId, portions: 1})
      })
    },
    removeRecipeFromCarte(state, data) {
      state.carte.forEach(carte => {
        if(carte.id == data.id)
          carte.recipes = carte.recipes.filter( v => v.id != data.recipeId)
      })
    },
    removeAllRecipesFromCarte(state, id) {
      state.carte.forEach(carte => {
        if(carte.id == id)
          carte.recipes = []
      })
    },
    changePortionsOfRecipeInCarte(state, data) {
      state.carte.forEach(carte => {
        if(carte.id == data.id)
          carte.recipes = carte.recipes.map( v =>
            v.id == data.recipeId
              ? {...v, portions: data.portions}
              : v
          )
      })
    },
    emptyStore(state) {
      state = initState
    }
  },
  actions: {
    async loadCarte({commit}) {
      commit('updateBusySearch', true)
      const res = await wAxios.get_auth_data(`v1/carte`)
      commit('updateCarte', res)
      commit('updateBusySearch', false)
    },
    async preloadCarte({commit, state}) {
      if(state.carte.length)
        return
      const res = await wAxios.get_auth_data(`v1/carte`)
      commit('updateCarte', res)
    },
    async addRecipeToCarte({commit, state}, item) {
      commit("addRecipeToCarte", item)
      const res = await wAxios.post_auth_data(`v1/carte`, state.carte)
      commit('updateCarte', res)
    },
    async removeRecipeFromCarte({commit, state}, item) {
      commit("removeRecipeFromCarte", item)
      const res = await wAxios.post_auth_data(`v1/carte`, state.carte)
      commit('updateCarte', res)
    },
    async removeAllRecipesFromCarte({commit, state}, item) {
      commit("removeAllRecipesFromCarte", item)
      const res = await wAxios.post_auth_data(`v1/carte`, state.carte)
      commit('updateCarte', res)
    },
    async changePortionsOfRecipeInCarte({commit, state}, item) {
      commit("changePortionsOfRecipeInCarte", item)
      const res = await wAxios.post_auth_data(`v1/carte`, state.carte)
      commit('updateCarte', res)
    },
    async updateCarteInfo({commit, state}, item) {
      commit("editOrAddCarte", item)
      const res = await wAxios.post_auth_data(`v1/carte`, state.carte)
      commit('updateCarte', res)
      return Promise.resolve('ok')
    },
    async removeCarte({commit, state}, item) {
      commit("removedCarte", item)
      const res = await wAxios.post_auth_data(`v1/carte`, state.carte)
      commit('updateCarte', res)
      return Promise.resolve('ok')
    },
    async changeSortingOfRecipesInCarte({commit, state}, cart) {
      commit('updateBusySearch', true)
      cart.newOrder.forEach( (recipeId, recipeSort) => {
        state.carte.forEach(carte => {
          if(carte.id == cart.id)
            carte.recipes = carte.recipes.map( v =>
              v.id == recipeId
                ? {...v, sort_order: recipeSort}
                : v
            )
        })
      })

      const res = await wAxios.post_auth_data(`v1/carte`, state.carte)
      setTimeout(_ => {
        commit('updateCarte', res)
        commit('updateBusySearch', false)
      }, 100)
    },


    async changeSortingOfSectionsInCarte({commit, state}, data) {
      commit('updateBusySearch', true)
      data.newOrder.forEach( (sectionId, sectionSort) => {
        state.carte.forEach(carte => {
          if(carte.id == sectionId)
            carte.sort_order = sectionSort
        })
      })

      const res = await wAxios.post_auth_data(`v1/carte`, state.carte)
      setTimeout(_ => {
        commit('updateCarte', res)
        commit('updateBusySearch', false)
      }, 100)
    },



  },
  getters: {
    carte: (state) =>  state.carte,
    busySearch: (state) =>  state.busySearch,
  }
}