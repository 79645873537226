import wAxios from '@/plugins/w/axios'
import format from '@/plugins/app/formatters'

const initState = {
  busySearch: false,
  daily: [],
  dailyId: ''
}

export default {
  namespaced: true,
  state: initState,
  mutations: {
    updateDailyId(state, dailyId) {
      state.dailyId = dailyId
    },
    updateDaily(state, data) {
      state.daily = data
    },
    updateBusySearch(state, data) {
      state.busySearch = data
    },
    addRecipeToDaily(state, data) {
      state.daily.forEach(daily => {
        if(daily.id == data.id)
          daily.recipes.push({id: data.recipeId})
      })
    },
    removeRecipeFromDaily(state, data) {
      state.daily.forEach(daily => {
        if(daily.id == data.id)
          daily.recipes = daily.recipes.filter( v => v.id != data.recipeId)
      })
    },
    removeAllRecipesFromDaily(state, data) {
      state.daily.forEach(daily => {
        if(daily.id == data)
          daily.recipes = []
      })
    },
    changePortionsOfRecipeInDaily(state, data) {
      state.daily.forEach(daily => {
        if(daily.id == data.id)
          daily.recipes = daily.recipes.map( v =>
            v.id == data.recipeId
              ? {...v, portions: data.portions}
              : v
          )
      })
    },
    emptyStore(state) {
      state = initState
    }
  },
  actions: {
    async loadDaily({commit, state}, item?) {
      if(!state.dailyId)
        commit('updateDailyId', format.dayId(new Date()))

      commit('updateBusySearch', true)
      if(item?.dailyId)
        commit('updateDailyId', item.dailyId)

      const res = await wAxios.get_auth_data(`v1/daily/${state.dailyId}`)
      commit('updateDaily', res)
      commit('updateBusySearch', false)
    },
    async addRecipeToDaily({commit, state}, item) {
      commit("addRecipeToDaily", item)
      const res = await wAxios.post_auth_data(`v1/daily`, state.daily)
      commit('updateDaily', res)
    },
    async removeRecipeFromDaily({commit, state}, item) {
      commit("removeRecipeFromDaily", item)
      const res = await wAxios.post_auth_data(`v1/daily`, state.daily)
      commit('updateDaily', res)
    },
    async removeAllRecipesFromDaily({commit, state}, item) {
      commit("removeAllRecipesFromDaily", item)
      const res = await wAxios.post_auth_data(`v1/daily`, state.daily)
      commit('updateDaily', res)
    },

    async changePortionsOfRecipeInDaily({commit, state}, item) {
      commit("changePortionsOfRecipeInDaily", item)
      const res = await wAxios.post_auth_data(`v1/daily`, state.daily)
      commit('updateDaily', res)
    },
    async changeSortingOfRecipesInDaily({commit, state}, data) {
      commit('updateBusySearch', true)
      data.newOrder.forEach( (recipeId, recipeSort) => {
        state.daily.forEach(carte => {
          if(carte.id == data.id)
            carte.recipes = carte.recipes.map( v =>
              v.id == recipeId
                ? {...v, sort_order: recipeSort}
                : v
            )
        })
      })

      const res = await wAxios.post_auth_data(`v1/daily`, state.daily)
      setTimeout(_ => {
        commit('updateDaily', res)
        commit('updateBusySearch', false)
      }, 100)
    },
  },
  getters: {
    daily: (state) =>  state.daily,
    dailyId: (state) =>  state.dailyId,
    busySearch: (state) =>  state.busySearch,
  }
}