import store from '@/store.ts'

export default async function(to, from, next) {
    const activeVenueId = store.getters['venues/activeVenueId']
    const venues = store.getters['venues/venues']
    const isLoaded = store.getters['venues/isLoaded']

    if(!venues.length && isLoaded) {
        next('/login/onboarding')
    }

    if(!activeVenueId && !to.fullPath.startsWith('/app/recipes')) {
        next('/app/recipes')
        return
    }

    if (!store.getters['wAuth/isLoggedIn']) {
        next('/login')
        return
    }

    return next()
}
