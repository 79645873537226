<template>
  <b-navbar toggleable="lg" type="dark" variant="primary" fixed="" class="p-0" v-if="user.name">
    <div class="container h-100">
      <img src="/logo.svg" @click.prevent="navTo('/app/daily')" class="pointer-event" alt="FUDOMA" id="logo">
      <b-navbar-toggle target="nav-collapse" class="ml-2 mt-2 mb-1 ml-lg-0 mt-lg-0 mb-lg-0"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav class="flex-grow-0 flex-lg-grow-1">
        <div class="d-flex justify-content-between w-100">
          <b-navbar-nav class="h-100 flex-row ml-2 mb-2 ml-lg-0 mb-lg-0">
            <venue-switcher />
            <b-nav-item v-if="activeVenueId" @click.prevent="navTo('/app/daily')" :active="isActive('planing')">Plánovanie</b-nav-item>
            <b-nav-item @click.prevent="navTo('/app/recipes')" :active="isActive('recipes')">Receptúry</b-nav-item>
          </b-navbar-nav>


          <b-navbar-nav class="align-items-center flex-row mr-2 mb-2 mr-lg-0 mb-lg-0">

            <b-button v-if="activeVenueId && !demoDomain" @click.prevent="navTo('/app/cart')" size="sm" href="#">Zoznam
            <template v-if="cart.recipes">
              ({{cart.recipes.length}})
            </template>
            </b-button>

            <b-nav-item-dropdown right class="user-dropdown" menu-class="p-2">
              <template #button-content>
                <b-button class="rounded-circle user-button p-0 text-uppercase" size="sm">{{ userInitials }}</b-button>
              </template>

              <b-dropdown-item link-class="p-2" @click.prevent="navTo('/app/user/settings')">Nastavenia</b-dropdown-item>

              <b-dropdown-item link-class="p-2" @click.prevent="onLogout">Odhlásiť</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </div>
      </b-collapse>
    </div>
  </b-navbar>
</template>
<script>
  import Vue from 'vue'
  import VenueSwitcher from "@/plugins/app@venues/venue-switcher"
  import {mapActions, mapGetters} from "vuex"
  import format from "@/plugins/app/formatters"

  export default Vue.extend({
    name: 'navigation',
    components: {VenueSwitcher},
    computed: {
      ...mapGetters('wAuth', ['user']),
      ...mapGetters('cart', ['cartPrice', 'cart']),
      ...mapGetters('venues', ['activeVenueId']),
      userInitials: function () {
        return this.user ? this.user.name[0] + this.user.surname[0] : ""
      },
      demoDomain() {
        return process.env.VUE_APP_DEMO_DOMAIN === document.location.host
      }
    },
    methods: {
      ...mapActions('wAuth', ['logout']),
      ...mapActions('recipes', ['searchRecipes']),
      isActive: function (link) {
        switch (link) {
          case 'planing':
                if(    this.$route.path.includes('daily')
                    || this.$route.path.includes('catering')
                    || this.$route.path.includes('carte'))
                  return true
              break
          case 'recipes':
            if(this.$route.path.includes('recipe') )
              return true
              break
        }

        return false
      },
      navTo: function (to) {
        if(this.$route.path === to) {
          if(to === '/app/recipes')
            this.$root.$emit('clearRecipesSearch')
        } else
          this.$router.push(to)
      },
      onLogout: async function () {
        await this.logout()
        this.$wToast.success('Boli ste úspešne odhlásení')
        this.navTo('/')
      },
      formatPrice: format.price,
    },
  })
</script>

<style lang="scss">
  #logo {
    max-height: 30px;
    margin-right: 30px;
  }
  .user-dropdown {
    a[role='button'] {
      padding: 0;
    }
    .dropdown-toggle::after {
      vertical-align: 0;
      border: 0;
    }
  }
</style>

<style lang="scss" scoped>
  .user-button {
    width: 35px;
    height: 35px;
  }
</style>
