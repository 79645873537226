import wAxios from '@/plugins/w/axios'
import './refreshExpired'

export default {
    namespaced: true,
    state: {
        status: '',
        _token: localStorage.getItem('wzo_vue_user_token') || '',
        user: JSON.parse(localStorage.getItem('wzo_vue_user_user')) || {},
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, data) {
            state.status = 'success'
            if (data.token) {
                state._token = data.token
                localStorage.setItem('wzo_vue_user_token', data.token)
            }

            if (data.user) {
                state.user = data.user
                localStorage.setItem('wzo_vue_user_user', JSON.stringify(data.user))
            }
        },
        auth_error(state) {
            state.status = 'error'
            state._token = ''
            localStorage.removeItem('wzo_vue_user_token')
            localStorage.removeItem('wzo_vue_user_user')
        },
        logout(state) {
            state.status = ''
            state._token = ''
            state.user = {}
            localStorage.removeItem('wzo_vue_user_token')
            localStorage.removeItem('wzo_vue_user_user')
            localStorage.removeItem('activeVenueId')
        }
    },
    actions: {
        login({commit}, credentials) {
            return new Promise((resolve, reject) => {
                commit('auth_request')

                wAxios.post('v1/user/login', credentials)
                  .then((response) => {
                      const token = response.token
                      const user = response.user
                      commit('auth_success', {token, user})
                      resolve(response)
                  }).catch(error => {
                    commit('auth_error')
                    reject(error)
                })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                wAxios.post_auth('v1/user/invalidate_token')
                    .finally(() => {
                        commit('logout')
                        commit('enums/emptyStore', {},  {root: true})
                        commit('venues/emptyStore', {},  {root: true})
                        commit('policies/emptyStore', {},  {root: true})
                        commit('recipes/emptyStore', {},  {root: true})
                        commit('carte/emptyStore', {},  {root: true})
                        commit('catering/emptyStore', {},  {root: true})
                        commit('daily/emptyStore', {},  {root: true})
                        commit('cart/emptyStore', {},  {root: true})
                        resolve(null)
                    })
            })
        },
        forgotPassword({commit}, credentials) {
            return new Promise((resolve, reject) => {
                wAxios.post_auth('v1/user/forgot', credentials)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        resetPassword({commit}, credentials) {
            return new Promise((resolve, reject) => {
                wAxios.post_auth('v1/user/reset', credentials)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        changePassword({commit}, credentials) {
            return new Promise((resolve, reject) => {
                wAxios.post_auth('v1/user/password_change', credentials)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        async refreshToken({commit}: {commit}) {
            try {
                const response: any = await wAxios.post_auth('v1/user/refresh_token')
                const token = response.token
                const user = response.user
                commit('auth_success', {token,user})
                return token
            } catch (error) {
                commit('auth_error')
                console.error(error)
            }
        },
        async userInfo({commit}) {
            return new Promise((resolve, reject) => {
                wAxios.get_auth('v1/user')
                    .then((response: any) => {
                        const user = response.user
                        commit('auth_success', {user})
                        resolve(user)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        updateUserInfo({commit}, data) {
            return new Promise((resolve, reject) => {
                wAxios.post_auth_data('v1/user', data)
                    .then((response) => {
                        const user = response
                        commit('auth_success', {user})
                        resolve(user)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        updateUserCompanyInfo({commit, state}, data) {
            return new Promise((resolve, reject) => {
                const user = {...state.user}
                  user.company = data

                wAxios.post_auth_data('v1/user', user)
                  .then((response) => {
                      const user = response
                      commit('auth_success', {user})
                      resolve(user)
                  }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    getters: {
        isLoggedIn: (state) =>  !!state._token,
        authStatus: (state) => state.status,
        user: (state) => state.user,
        token: (state) => state._token,
    }
}