<template>
  <div id="app">
    <router-view/>
    <w-progressbar></w-progressbar>
  </div>
</template>

<script>
export default {
  components: {
    'w-progressbar': () => import('@/plugins/w/progressbar/w-progressbar.vue'),
  }
}
</script>

<style lang="scss" scoped>
#app {
  min-height: 100vh;
}
</style>