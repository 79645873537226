import wAxios from '@/plugins/w/axios'

const venueFields = ['name', 'street', 'city', 'zip', 'country']

function prefillVenue(venue) {
  if(!venue)
    venue = {}
  venueFields.forEach(f => {
    if(!venue[f])
      venue[f] = ""
  })

  return venue
}

const initState = {
  activeVenueId: +localStorage.getItem('activeVenueId'),
  venues: [],
  isLoaded: false,
}

export default {
  namespaced: true,
  state: initState,
  mutations: {
    updateVenue(state, venue) {
      const venueExist = !!state.venues.filter(v => v.id === venue.id).length

      if(venueExist){
        state.venues = [ ...state.venues.map(v => v.id !== venue.id ? v : {...v, ...venue})]
      } else {
        state.venues = [ ...state.venues, venue]
        state.activeVenueId = venue.id
        localStorage.setItem('activeVenueId', state.activeVenueId)
      }
    },

    fillVenues(state, data) {
      state.venues = data.data
      state.isLoaded = true
    },
    setActiveVenueId(state, venueId) {
      state.activeVenueId = venueId
      localStorage.setItem('activeVenueId', state.activeVenueId)
    },
    deleteVenue(state, id) {
      state.venues = state.venues.filter(v => v.id !== id)
      if(state.venues.length)
        state.activeVenueId = state.venues[0].id
      else
        state.activeVenueId = null
      localStorage.setItem('activeVenueId', state.activeVenueId)
    },
    emptyStore(state) {
      state = initState
      localStorage.removeItem('activeVenueId')
    }
  },
  actions: {
    switchVenueId({commit}, id) {
      commit('setActiveVenueId', id)
    },
    async preloadVenues({commit}) {
      const data = await wAxios.get_auth_data('v1/venue')

      commit('fillVenues', {data})
      if(data.length && !+localStorage.getItem('activeVenueId'))
        commit('setActiveVenueId', data[0].id)

    },
    updateVenueInfo({commit}, data) {
      return new Promise((resolve, reject) => {
        wAxios.post_auth_data('v1/venue' + (data.id ? `/${data.id}` : ''), data.venue)
          .then((venue) => {
            commit('updateVenue', venue)
            resolve(venue)
          }).catch(error => {
          reject(error)
        })
      })
    },

    deleteVenue({commit}, id) {
      return new Promise((resolve, reject) => {
        wAxios.delete_auth_data(`v1/venue/${id}`)
          .then((res) => {
            commit('deleteVenue', id)
            resolve(res)
          }).catch(error => {
          reject(error)
        })
      })
    },

  },
  getters: {
    venues: (state) =>  state.venues,
    isLoaded: (state) =>  state.isLoaded,
    activeVenueId: (state) => state.activeVenueId,
    activeVenue: (state) => {
      if(!state.activeVenueId)
        return prefillVenue({})

      return {... state.venues.find( v => v.id == state.activeVenueId)}
    },
  }
}
