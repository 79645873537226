<template>
  <div>
    <Navigation></Navigation>
    <router-view/>
  </div>
</template>

<script>

import Navigation from "@/plugins/app/_layouts/components/navigation"

export default {
  components: {
    Navigation
  },
  methods: {

  }
}
</script>
