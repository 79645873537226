import wAxios from '@/plugins/w/axios'

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

const initState = {
  cart: [],
  payload: [],
  list: []
}

export default {
  namespaced: true,
  state: initState,
  mutations: {
    updateCart(state, data) {
      const cart = {
        recipes: [],
        products: []
      }

      const groupById = groupBy('group_id')
      for (const [key, value] of Object.entries(groupById(data.list))) {
        const products = value as any[]

        const recipe = {
          group_id: key,
          group_price: value[0].group_price,
          name: value[0].group_name,
          plan: value[0].plan,
          portions: value[0].portions,
          products: products.map( item => {
            return  {
              quantity: item.quantity,
              id: item.id,
              ... item.ingredient
            }
          })
        }
        cart.recipes.push(recipe)
      }

      state.payload = data.list
      state.cart = cart
    },
    emptyStore(state) {
      state = initState
    },
    updateList(state, data) {
      state.list = data.calculation.map(item => ({...item, on_stock: 0 }))

    },
    removeIngredient(state, id) {
      state.list = state.list.filter(item => {
        return item.id !== id
      })
    }
  },
  actions: {
    async submitCart({commit}) {
      const res = await wAxios.post_auth_data(`v1/list/order`, {})
      commit('updateCart', res)
      return res
    },

    async loadCart({commit}) {
      const res = await wAxios.get_auth_data(`v1/list`)
      commit('updateCart', res)
    },
    async loadList({commit}) {
      const res = await wAxios.get_auth_data(`v1/list/calculate`)
      commit('updateList', res)
    },
    async addToCart({commit}, items) {
      const res = await wAxios.post_auth_data(`v1/list/add/recipe`, items)
      commit('updateCart', res)
    },
    async addItemToCart({commit, state}, payload) {
      const res = await wAxios.post_auth_data(`v1/list/add/product`, payload)
      commit('updateCart', res)
    },

    async changeItemPortions({commit, state}, payload) {
      const res = await wAxios.post_auth_data(`v1/list`, payload)
      commit('updateCart', res)
    },

    async removeCart({commit, state}) {
      const payload = []
      state.payload.forEach(item => {
        payload.push({id: item.id})
      })
      const res = await wAxios.delete_auth_data(`v1/list`, payload)
      commit('updateCart', res)
    },
    async removeCartRecipe({commit, state}, recipeId) {
      const payload = []
      state.payload.forEach(item => {
        if(item.group_id == recipeId)
          payload.push({id: item.id})
      })
      const res = await wAxios.delete_auth_data(`v1/list`, payload)
      commit('updateCart', res)
    },
    async removeCartItem({commit, state}, id) {
      const payload = [{id}]
      // console.log(payload)
      const res = await wAxios.delete_auth_data(`v1/list`, payload)
      commit('updateCart', res)
    },
    async finishListOrder({commit, state}, payload) {
      await wAxios.post_auth_data(`v1/list/order`, payload)

    },

  },
  getters: {
    cart: (state) =>  state.cart,
    list: (state) =>  state.list,
  }
}

