import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import VueToast from 'vue-toast-notification'
import BasicLayout from '@/plugins/app/_layouts/basic.vue'
import MainLayout from '@/plugins/app/_layouts/main.vue'
import loginGuard from '@/plugins/w/loginGuard'

Vue.use(VueToast, {
  position: 'bottom'
})
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Entry',
    component: BasicLayout,
    children: [
      {
        path: '/',
        name: 'Login',
        component: () => import('@/plugins/app/login/login.vue')
      },
      {
        path: 'forgot',
        name: 'Forgot',
        component: () => import('@/plugins/app/login/forgot.vue')
      },
      {
        path: 'reset',
        name: 'Reset',
        component: () => import('@/plugins/app/login/reset.vue')
      },
      {
        path: 'onboarding',
        name: 'Onboarding',
        component: () => import('@/plugins/app/user/onboarding.vue')
      }
    ]
  },
  {
    path: '/app',
    name: 'App',
    component: MainLayout,
    beforeEnter: loginGuard,
    children: [
      {
        path: '/',
        redirect: '/app/daily'
      },
      {
        path: 'daily',
        name: 'Daily',
        component: () => import('@/plugins/app/daily/daily.vue')
      },
      {
        path: 'carte',
        name: 'Carte',
        component: () => import('@/plugins/app/carte/carte.vue')
      },
      {
        path: 'catering',
        name: 'Catering',
        component: () => import('@/plugins/app/catering/catering.vue')
      },
      {
        path: 'cart',
        name: 'Cart',
        component: () => import('@/plugins/app/cart/cart.vue')
      },
      {
        path: 'cart2',
        name: 'Cart2',
        component: () => import('@/plugins/app/cart/cart2.vue')
      },
      {
        path: 'recipes',
        name: 'Recipes',
        component: () => import('@/plugins/app/recipes/recipes.vue')
      },
      {
        path: 'recipes/:id',
        name: 'RecipeDetail',
        component: () => import('@/plugins/app/recipes/recipes.vue')
      },
      {
        path: 'recipe-create',
        name: 'RecipesCreate',
        component: () => import('@/plugins/app/recipes/recipes-create.vue')
      },
      {
        path: 'recipe-duplicate/:id',
        name: 'RecipesDuplicateCopy',
        component: () => import('@/plugins/app/recipes/recipes-create.vue')
      },
      {
        path: 'recipe-edit/:id',
        name: 'RecipesEdit',
        component: () => import('@/plugins/app/recipes/recipes-create.vue')
      },
      {
        path: 'recipes/duplicate',
        name: 'RecipesDuplicate',
        component: () => import('@/plugins/app/recipes/recipes-create.vue')
      },
      {
        path: 'user/policy',
        name: 'Policy',
        component: () => import('@/plugins/app/policy/policy.vue')
      },
      {
        path: 'user/settings',
        name: 'Settings',
        component: () => import('@/plugins/app/user/settings.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to !== from) {
    //Vue.$toast.clear()
  }
  next()
})

export default router
