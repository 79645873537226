import wAxios from '@/plugins/w/axios'

const initState = {
  vendors: [],
  cuisine: [],
  diet: [],
  units: []
}

export default {
  namespaced: true,
  state: initState,
  mutations: {
    fillVendors(state, data) {
      state.vendors = data
    },
    fillCuisine(state, data) {
      state.cuisine = data
    },
    fillDiet(state, data) {
      state.diet = data
    },
    fillUnits(state, data) {
      state.units = data
    },
    emptyStore(state) {
      state = initState
    }
  },
  actions: {
    async preloadEnums({commit}: { commit }) {
      const enums = await Promise.all(
        ['vendor', 'cuisine', 'diet', 'unit'].map(
          async (e) => await wAxios.get_auth_data(`v1/enum/${e}`)
        )
      )

      ;['fillVendors', 'fillCuisine', 'fillDiet', 'fillUnits'].forEach(
        (e, i) => commit(e, enums[i])
      )


    }
  },
  getters: {
    cuisine: (state) =>  state.cuisine,
    diet: (state) =>  state.diet,
    vendors: (state) =>  state.vendors,
    units: (state) =>  state.units,
    vendorsMapNames: (state) =>  {
      const map = []
      state.vendors.forEach(e => map[e.id] = e.name)
      return map
    },
    vendorsMap: (state) =>  {
      const map = []
      state.vendors.forEach(e => map[e.id] = e)
      return map
    },
    unitsMap: (state) =>  {
      const map = []
      state.units.forEach(e => map[e.id] = e)
      return map
    },
  }
}