import Vue from 'vue'
import Vuex from 'vuex'
import wAuth from '@/plugins/w/authStore/wAuth.store'
import enums from '@/plugins/app@enums/store'
import venues from '@/plugins/app@venues/store'
import policies from '@/plugins/app/policy/store'
import recipes from '@/plugins/app/recipes/store'
import carte from '@/plugins/app/carte/store'
import catering from '@/plugins/app/catering/store'
import daily from '@/plugins/app/daily/store'
import cart from '@/plugins/app/cart/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    wAuth,
    enums,
    venues,
    policies,
    recipes,
    carte,
    catering,
    daily,
    cart,
  }
})
