import store from "@/store"

export default {
  async loadAllData() {
    if (!store.getters['wAuth/isLoggedIn'] || !store.getters['wAuth/user'].company)
      return

    await Promise.all([
      store.dispatch('enums/preloadEnums'),
      store.dispatch('venues/preloadVenues')
    ])

    if(!store.getters['venues/activeVenueId'])
      return

    await store.dispatch('cart/loadCart')
  },

  async loadVenueData() {
    if (!store.getters['wAuth/isLoggedIn'] || !store.getters['venues/activeVenueId'])
      return

    await store.dispatch('carte/loadCarte')
    await store.dispatch('catering/loadCatering')
    await store.dispatch('daily/loadDaily')
    await store.dispatch('recipes/loadRecipes')
    await store.dispatch('cart/loadCart')

  }
}