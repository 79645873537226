import wAxios from '@/plugins/w/axios'

const initState = {
  busySearch: false,
  catering: []
}

export default {
  namespaced: true,
  state: initState,
  mutations: {
    updateCatering(state, data) {
      state.catering = data
    },
    updateBusySearch(state, data) {
      state.busySearch = data
    },
    removeCatering(state, data) {
      state.catering = state.catering.filter( v => v.id != data.id)
    },
    editOrAddCatering(state, data) {
      if(data.id)
        state.catering = state.catering.map( v =>
          v.id == data.id
            ? {...v, name: data.name, date: data.date}
            : v
        )
      else
        state.catering.push(data)

    },
    addRecipeToCatering(state, data) {
      state.catering.forEach(catering => {
        if(catering.id == data.id)
          catering.recipes.push({id: data.recipeId, portions: 1})
      })
    },
    removeRecipeFromCatering(state, data) {
      state.catering.forEach(catering => {
        if(catering.id == data.id)
          catering.recipes = catering.recipes.filter( v => v.id != data.recipeId)
      })
    },
    removeAllRecipesFromCatering(state, id) {
      state.catering.forEach(catering => {
        if(catering.id == id)
          catering.recipes = []
      })
    },
    changePortionsOfRecipeInCatering(state, data) {
      state.catering.forEach(catering => {
        if(catering.id == data.id)
          catering.recipes = catering.recipes.map( v =>
            v.id == data.recipeId
              ? {...v, portions: data.portions}
              : v
          )
      })
    },
    emptyStore(state) {
      state = initState
    }
  },
  actions: {
    async loadCatering({commit}) {
      commit('updateBusySearch', true)
      const res = await wAxios.get_auth_data(`v1/catering`)
      commit('updateCatering', res)
      commit('updateBusySearch', false)
    },
    async preloadCatering({commit, state}) {
      if(state.catering.length)
        return

      const res = await wAxios.get_auth_data(`v1/catering`)
      commit('updateCatering', res)
    },
    async silentUpdateCatering({commit}, item) {
      const res = await wAxios.post_auth_data(`v1/catering`, item)
      commit('updateCatering', res)
      return Promise.resolve('ok')
    },
    async addRecipeToCatering({commit, state}, item) {
      commit("addRecipeToCatering", item)
      const res = await wAxios.post_auth_data(`v1/catering`, state.catering)
      commit('updateCatering', res)
    },
    async removeRecipeFromCatering({commit, state}, item) {
      commit("removeRecipeFromCatering", item)
      const res = await wAxios.post_auth_data(`v1/catering`, state.catering)
      commit('updateCatering', res)
    },
    async removeAllRecipesFromCatering({commit, state}, item) {
      commit("removeAllRecipesFromCatering", item)
      const res = await wAxios.post_auth_data(`v1/catering`, state.catering)
      commit('updateCatering', res)
    },
    async changePortionsOfRecipeInCatering({commit, state}, item) {
      commit("changePortionsOfRecipeInCatering", item)
      const res = await wAxios.post_auth_data(`v1/catering`, state.catering)
      commit('updateCatering', res)
    },

    async updateCateringInfo({commit, state}, item) {
      commit("editOrAddCatering", item)
      const res = await wAxios.post_auth_data(`v1/catering`, state.catering)
      commit('updateCatering', res)
      return Promise.resolve('ok')
    },
    async removeCatering({commit, state}, item) {
      commit("removeCatering", item)
      const res = await wAxios.post_auth_data(`v1/catering`, state.catering)
      commit('updateCatering', res)
      return Promise.resolve('ok')
    },
    async changeSortingOfRecipesInCatering({commit, state}, data) {
      commit('updateBusySearch', true)
      data.newOrder.forEach( (recipeId, recipeSort) => {
        state.catering.forEach(carte => {
          if(carte.id == data.id)
            carte.recipes = carte.recipes.map( v =>
              v.id == recipeId
                ? {...v, sort_order: recipeSort}
                : v
            )
        })
      })

      const res = await wAxios.post_auth_data(`v1/catering`, state.catering)
      setTimeout(_ => {
        commit('updateCatering', res)
        commit('updateBusySearch', false)
      }, 100)
    },
  },
  getters: {
    catering: (state) =>  state.catering,
    busySearch: (state) =>  state.busySearch,
  }
}