<template>
  <div id="basic" class="container">
    <router-view/>
  </div>
</template>

<script>
export default {

}
</script>
<style lang="scss" scoped>
#basic{
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  * {
    flex-grow: 1;
  }
}
</style>
