import moment from "moment"
import store from '@/store'

export default {
  now(date) {
    return moment.utc(date).fromNow()
  },
  day(date) {
    return moment.utc(date).format('l')
  },
  dayId(date) {
    return moment.utc(date).format("YYYY-MM-DD")
  },
  dayName(date) {
    return moment.utc(date).format("dddd")
  },
  isPast(date) {
    return 0 > moment(date).diff(moment().subtract(1, 'days'))
  },
  quantity(item) {
    const unitsMap = store.getters['enums/unitsMap']
    return `${item.quantity} ${unitsMap[item.unit_id].name}`
  },
  ingredientVolume(ingredient) {
    return `${ingredient.volume} ${ingredient.unit.name}`
  },
  unit(id) {
    const unitsMap = store.getters['enums/unitsMap']
    return unitsMap[id] ? `${unitsMap[id].name}` : ''
  },
  price (v) {
    v = parseFloat(v)
    if(v === 0)
      return '0,00'
    if(!v)
      return

    return v.toFixed(2).toString().split('.').join(',')
  },
  params: function (params) {
    let output = []
    Object.keys(params).forEach( k => output.push(`${k}=${params[k]}`))
    return output.join("&")
  },
  portions(i) {
    switch (i) {
      case 1:
        return 'porcia'
      case 2:
      case 3:
      case 4:
        return 'porcie'
      default:
        return 'porcií'

    }
  }
}
