import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'

import '@/plugins/app/sorting.js'

//Bootstrap

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue, {
  BButton: { variant: 'secondary' },
  BOverlay: { bgColor: 'white', spinnerType: 'grow', spinnerVariant:'primary', blur: '4px', noFade: true},
})
Vue.use(IconsPlugin)

import '@/plugins/app/_theme/global.scss'

import VModal from 'vue-js-modal'
Vue.use(VModal, {
  dynamicDefaults: {
    draggable: true,
    resizable: true,
    height: 'auto',
    scrollable: true,
  }
})

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
Vue.component('vue-dropzone', vue2Dropzone)

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

Vue.use(CoolLightBox)

import moment from "moment"
moment.locale('sk')

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import wToast from '@/plugins/w/toast'
Vue.prototype.$wToast = wToast

Vue.config.productionTip = false

import load from '@/plugins/app/load'
load.loadAllData()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
